import $ from 'jquery'
import magnificPopup from 'magnific-popup'

const $doc = $(document)

function prepopulateEmail() {
    const email = $('#email-address').val();
    const input = $('#newsletter-modal').find('input[type=email]');
    input.val(email);
}

export default function initNewsletterModal() {
    $('.js-open-newsletter').magnificPopup({
        type:'inline',
        midClick: true
    });
      
    $doc.on('click', '.js-open-newsletter', prepopulateEmail );
    $('#email-address').on('keypress', function(event) {
        if (event.key === "Enter") {
            $('.js-open-newsletter').trigger('click');
        }
    });
}