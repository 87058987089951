import $ from 'jquery';

const $doc = $(document);

function scrollToTop(event) {
  event.preventDefault();
  $('html, body').animate({scrollTop : 0}, 1000);
  $(this).blur();
}

export default function initScrolls() {
  $doc.on('click', '.js-scroll-top', scrollToTop );
}