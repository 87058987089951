import $ from 'jquery'

const $doc = $(document)

let maxHeight = 0;
$('.sub-menu').each(function(){
  let itemHeight = $(this).outerHeight(true);
  maxHeight = Math.max(maxHeight, itemHeight)
});

function showNav() {
  const bg = $('.header').find('.header--bg')
  const submenu = $(this).find('.sub-menu')
  if (!bg.length) {
    $('.js-header').append('<div class="header--bg"></div>')
    $('.header--bg').css( { height: `calc(100% + ${maxHeight}px)` } )
  }
  submenu.addClass('toggled-on')
  $('.sub-menu').not(submenu).removeClass('toggled-on')
  $(this).addClass('is-active')
  $('.menu-item-has-children').not(this).removeClass('is-active')
}

function hideNav() {
  $('.header--bg').remove()
  $(this).find('.sub-menu').removeClass('toggled-on')
  $('.menu-item-has-children').removeClass('is-active')
}

export default function initNav(){
  $doc.on('mouseenter', '.nav--main > .nav__list > .menu-item-has-children', showNav )
  $doc.on('mouseleave', '.js-header', hideNav )
}
